// import external modules
import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

// import internal(own) modules
import registerServiceWorker from "./registerServiceWorker";
import { store } from "./redux/storeConfig/store";
import ReduxToastr from "react-redux-toastr";

import "font-awesome/css/font-awesome.min.css";
import "./index.scss";

import Spinner from "./components/spinner/spinner";
import { Router } from "react-router-dom";
import history from "./app/history";

const element = document.getElementById('root');
if (!element) throw new Error('No se encontró el elemento con el id root');

const root = createRoot(element);

const LazyApp = lazy(() => import("./app/app"));

root.render(
  <Router history={history}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <LazyApp />
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </Suspense>
    </Provider>
  </Router>
);
registerServiceWorker();
